// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-development-tsx": () => import("./../../src/pages/development.tsx" /* webpackChunkName: "component---src-pages-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-templates-portfolio-category-tsx": () => import("./../../src/templates/portfolio_category.tsx" /* webpackChunkName: "component---src-templates-portfolio-category-tsx" */),
  "component---src-templates-portfolio-gallery-tsx": () => import("./../../src/templates/portfolio_gallery.tsx" /* webpackChunkName: "component---src-templates-portfolio-gallery-tsx" */)
}

